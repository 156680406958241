import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { getCurrentUser } from '@store/actions/current_user.actions';
import { getDeviceModels } from '@store/actions/device_models.actions';
import { TuiThemeNightService } from '@taiga-ui/addon-doc';
import { WebsocketService } from '@services/websocket.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.less'],
})
export class MainComponent implements OnInit, OnDestroy {
  expandState = false;

  constructor(
    @Inject(TuiThemeNightService) readonly night: TuiThemeNightService,
    private store: Store,
    private websocketService: WebsocketService,
  ) {
    this.night.next(!!window.localStorage.getItem('theme') || false);
  }

  ngOnInit() {
    const sessionStorageToken = sessionStorage.getItem('cmsToken');

    if (!sessionStorageToken) {
      sessionStorage.removeItem('cmsToken');
      window.location.href = '/';
    }

    this.store.dispatch(getCurrentUser());
    this.store.dispatch(getDeviceModels());
  }

  changeSidebarState($event: any) {
    this.expandState = $event;
  }

  ngOnDestroy() {
    sessionStorage.clear();
    this.websocketService.disconnectUserSocket();
  }
}
