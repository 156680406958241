import { NgModule } from '@angular/core';
import {
  TuiAccordionModule,
  TuiBadgeModule,
  TuiCheckboxBlockModule,
  TuiCheckboxLabeledModule,
  TuiCheckboxModule,
  TuiComboBoxModule,
  TuiDataListWrapperModule,
  TuiFilterByInputPipeModule,
  TuiInputDateTimeModule,
  TuiInputFilesModule,
  TuiInputModule,
  TuiInputNumberModule,
  TuiInputPasswordModule,
  TuiInputTimeModule,
  TuiLineClampModule,
  TuiMarkerIconModule,
  TuiProgressModule,
  TuiPushModule,
  TuiRadioBlockModule,
  TuiRadioLabeledModule,
  TuiSelectModule,
  TuiStringifyContentPipeModule,
  TuiTabsModule,
  TuiTextareaModule,
  TuiTilesModule,
  TuiToggleModule,
} from '@taiga-ui/kit';
import {
  TuiAlertModule,
  TuiButtonModule,
  TuiDataListModule,
  TuiDialogModule,
  TuiDropdownModule,
  TuiErrorModule,
  TuiFlagPipeModule,
  TuiGroupModule,
  TuiHintModule,
  TuiHostedDropdownModule,
  TuiLabelModule,
  TuiLoaderModule,
  TuiPrimitiveCheckboxModule,
  TuiRootModule,
  TuiScrollbarModule,
  TuiSvgModule,
  TuiTextfieldControllerModule,
  TuiTooltipModule,
} from '@taiga-ui/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TuiTableModule, TuiTablePaginationModule } from '@taiga-ui/addon-table';
import {
  TuiActiveZoneModule,
  TuiAutoFocusModule,
  TuiHoveredModule,
  TuiLetModule,
  TuiObscuredModule,
  TuiValueChangesModule,
} from '@taiga-ui/cdk';
import { TuiCurrencyPipeModule } from '@taiga-ui/addon-commerce';
import { TuiColorPickerModule, TuiColorSelectorModule } from '@tinkoff/tui-editor';

const ALL_TAIGA_MODULES = [
  FormsModule,
  ReactiveFormsModule,
  TuiInputModule,
  TuiHintModule,
  TuiTextfieldControllerModule,
  TuiInputPasswordModule,
  TuiButtonModule,
  TuiAccordionModule,
  TuiTabsModule,
  TuiRootModule,
  TuiDialogModule,
  TuiAlertModule,
  TuiProgressModule,
  TuiSelectModule,
  TuiDataListModule,
  TuiRadioBlockModule,
  TuiGroupModule,
  TuiTableModule,
  TuiMarkerIconModule,
  TuiSvgModule,
  TuiTablePaginationModule,
  TuiScrollbarModule,
  // TuiThemeNightModule,
  TuiToggleModule,
  TuiLoaderModule,
  TuiComboBoxModule,
  TuiDataListWrapperModule,
  TuiFlagPipeModule,
  TuiHostedDropdownModule,
  TuiActiveZoneModule,
  TuiDropdownModule,
  TuiInputNumberModule,
  TuiCurrencyPipeModule,
  TuiColorPickerModule,
  TuiColorSelectorModule,
  TuiStringifyContentPipeModule,
  TuiFilterByInputPipeModule,
  TuiInputDateTimeModule,
  TuiCheckboxLabeledModule,
  TuiInputTimeModule,
  TuiCheckboxModule,
  TuiTooltipModule,
  TuiHoveredModule,
  TuiCheckboxBlockModule,
  TuiCheckboxLabeledModule,
  TuiInputDateTimeModule,
  TuiInputFilesModule,
  TuiErrorModule,
  TuiRadioLabeledModule,
  TuiTextareaModule,
  TuiObscuredModule,
  TuiLabelModule,
  TuiValueChangesModule,
  TuiLetModule,
  TuiPushModule,
  TuiBadgeModule,
  TuiTilesModule,
  TuiPrimitiveCheckboxModule,
  TuiLineClampModule,
  TuiAutoFocusModule,
];

@NgModule({
  imports: ALL_TAIGA_MODULES,
  exports: ALL_TAIGA_MODULES,
})
export class TaigaModule {}
