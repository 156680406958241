<div class="create-object__wrapper">
  <div class="create-object" [formGroup]="formGroup">

    <div class="create-object__tabs">
      <button
        *ngFor="let tab of settingsTabs; index as i;"
        (click)="currentTab = tab"
        [class.is-active]="currentTab === tab">
        {{tab | translate}}
      </button>
    </div>
    <tui-scrollbar>
      <div class="create-object__content" [style.display]="currentTab === 'General' ? 'flex' : 'none'">
        <div class="row">
          <span>{{"LOGIN"| translate}} *</span>
          <tui-input
            formControlName="username"
            tuiTextfieldSize="s"
            [pseudoFocus]="!formGroup.controls['username'].valid && formGroup.controls['username'].touched"
          >

            {{ 'Enter login' | translate }}
            <input tuiTextfield type="text" translate="no"/>
          </tui-input>
          <tui-error [error]="formGroup.get('username')?.errors?.['message']"></tui-error>
          <tui-error
            *ngIf="formGroup.get('username')?.errors?.['maxlength']"
            [error]="'Ensure this field has no more than 150 characters' | translate"
          ></tui-error>

        </div>

        <div class="row">
          <span>{{"First name"| translate}} *</span>
          <tui-input
            formControlName="fullname"
            tuiTextfieldSize="s"
            [pseudoFocus]="!formGroup.controls['fullname'].valid && formGroup.controls['fullname'].touched"
          >
            {{ 'Enter full name' | translate }}
            <input tuiTextfield type="text" translate="no"/>
          </tui-input>
          <tui-error [error]="formGroup.get('fullname')?.errors?.['message']"></tui-error>
          <tui-error
            *ngIf="formGroup.get('fullname')?.errors?.['maxlength']"
            [error]="'Ensure this field has no more than 150 characters' | translate"
          ></tui-error>
        </div>

        <div class="row">
          <span>{{"E-mail"| translate}} *</span>
          <tui-input
            formControlName="email"
            tuiTextfieldSize="s"
            [pseudoFocus]="!formGroup.controls['email'].valid && formGroup.controls['email'].touched"
          >
            example@example.example
            <input tuiTextfield type="email" translate="no"/>
          </tui-input>
          <tui-error [error]="formGroup.get('email')?.errors?.['message']"></tui-error>

        </div>

        <div class="flex-row">
          <div class="row">
            <span>{{'language' | translate}}</span>
            <tui-select
              formControlName="language"
              [valueContent]="stringify(availableLanguages)"
              tuiTextfieldSize="s"
            >
              <ng-template tuiDataList>
                <tui-data-list>
                  <button
                    *ngFor="let item of availableLanguages"
                    tuiOption
                    [value]="item.id"
                  >
                    {{ item.title }}
                  </button>
                </tui-data-list>
              </ng-template>
            </tui-select>
          </div>

          <div class="row">
            <span>{{'timezone' | translate}}</span>
            <tui-select
              formControlName="timezone"
              [valueContent]="stringify(availableTimezones)"
              tuiTextfieldSize="s"
            >
              <ng-template tuiDataList>
                <tui-data-list>
                  <button
                    *ngFor="let item of availableTimezones"
                    tuiOption
                    [value]="item.id"
                  >
                    {{ item.title }}
                  </button>
                </tui-data-list>
              </ng-template>
            </tui-select>
          </div>
        </div>

        <div class="row">
          <span>{{"Password"| translate}}</span>
          <div>
            <button tuiButton appearance="transparent" size="s"
                    (click)="openChangePasswordDialog()"
            >{{ 'Change password' | translate }}</button>
          </div>
        </div>

      </div>


      <div [style.display]="currentTab === 'Additional' ? 'contents' : 'none'">

        <tui-accordion>
          <tui-accordion-item [size]="'s'" [borders]="'top-bottom'">
            {{'Address display format' | translate}}
            <div tuiAccordionItemContent>

              <!--              <tui-tiles-->
              <!--                class="tiles"-->
              <!--                [(order)]="order"-->
              <!--              >-->
              <!--                <tui-tile-->
              <!--                  *ngFor="let item of availableAddressFormat; let index = index"-->
              <!--                  tuiTileHandle-->
              <!--                  class="tile"-->
              <!--                  [style.order]="order.get(index)"-->
              <!--                >-->
              <!--                  <div class="content">-->
              <!--                    <tui-svg class="not-hover" [src]="'tuiIconDrag'"></tui-svg>-->

              <!--                    <tui-checkbox-labeled [formControl]="item?.control">-->
              <!--                      {{ item.name | translate }}-->
              <!--                    </tui-checkbox-labeled>-->
              <!--                  </div>-->
              <!--                </tui-tile>-->
              <!--              </tui-tiles>-->

              <div cdkDropList
                   class="list-container"
                   (cdkDropListDropped)="drop($event)">

                <ng-container
                  *ngFor="let item of availableAddressFormat; let index = index"
                >
                  <div
                    class="content-item"
                    cdkDrag
                    cdkDragHandle
                    cdkDragLockAxis="y"
                    (cdkDragStarted)="isDragging = true"
                    (cdkDragEnded)="isDragging = false"
                    [class]="isDragging ? 'grabbing' : 'grab'"
                  >
                    <ng-template cdkDragPreview [matchSize]="true">

                      <div class="cdk-drag-preview-custom">
                        <tui-svg [src]="'tuiIconDrag'" class="grabbing"></tui-svg>
                        <p>{{ item.name | translate }}</p>
                      </div>
                    </ng-template>
                    <tui-svg [src]="'tuiIconDrag'" [class]="isDragging ? 'grabbing' : 'grab'"></tui-svg>

                    <tui-checkbox-labeled [formControl]="item?.control">
                      {{ item.name | translate }}
                    </tui-checkbox-labeled>

                  </div>
                </ng-container>

              </div>

            </div>
          </tui-accordion-item>

          <tui-accordion-item [size]="'s'" [borders]="'top-bottom'">
            {{ 'Displaying tabs in the navigation menu' | translate }}
            <div
              *ngIf="availableVisibleTabsNames?.length"
              tuiAccordionItemContent
              orientation="vertical"
              tuiGroup
              class="group"
              [collapsed]="true"
            >
              <tui-checkbox-block
                *ngFor="let tab of availableVisibleTabsNames"
                contentAlign="right"
                [formControl]="tab.control"
                size="m"
              >
                <div class="checkbox-block-content">
                  <tui-svg [src]="tab?.iconSrc" class="not-hover"></tui-svg>
                  <span>{{tab?.title | translate}}</span>
                </div>
              </tui-checkbox-block>

            </div>
          </tui-accordion-item>
        </tui-accordion>

      </div>
    </tui-scrollbar>
  </div>

  <div class="btn-block">
    <div>
      <button tuiButton type="button" appearance="outline" size="s" (click)="onCancel()">
        {{ "Cancel" | translate }}
      </button>
      <button tuiButton (click)="onSave()" size="s">
        {{ "SAVE" | translate }}
      </button>
    </div>
  </div>

</div>
