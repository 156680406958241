import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { RetranslatorsService } from '@services/retranslators.service';

@Injectable({
  providedIn: 'root',
})
export class WebsocketService {
  private userSocket: null | WebSocket = null;

  public isConnected: boolean = false;

  constructor(private retranslatorsService: RetranslatorsService) {}

  disconnectUserSocket() {
    this.userSocket?.close();
    this.userSocket = null;
    this.isConnected = false;
  }

  connectUserSocket(currentUserInfo: any) {
    if (!this.isConnected) {
      const userId = currentUserInfo.id;

      const token = sessionStorage.getItem('cmsToken');
      this.userSocket = new WebSocket(
        `${environment.websocketUrl}live-cms-data/${userId}?token=${token}`,
      );
      this.userSocket.onopen = () => {
        console.log(`connected ${userId}`);
        this.isConnected = true;
      };
      this.userSocket.onmessage = (data: any) => {
        const socketData = JSON.parse(data.data);
        if (socketData.type === 'retransmission__status') {
          this.retransmissionStatusHandler(socketData);
        }
      };
      this.userSocket.onclose = () => {
        console.log(`disconnected ${userId}`);
        this.isConnected = false;

        const token = sessionStorage.getItem('cmsToken');
        if (token) {
          this.connectUserSocket(currentUserInfo);
        }
      };
    }
  }

  retransmissionStatusHandler(data: any) {
    this.retranslatorsService.retransmissionStatus$.next(data.data);
  }
}
